import { NormalText } from '@/shared/components/Text.styled';
import { Spinner } from '@checkrx/pay-component-library';

import { LoadingScreen, TabWrapper } from '@/app/wrappers/Containers';
import { useWorkerProfile } from '@/services/useWorkerProfile';
import PageNavBar from '@/shared/components/PageNavBar';
import { WorkerProfile } from '@/shared/types';
import { TaxesList } from './TaxesList';

export default function TaxesPage() {
  const { data: profile, isLoading } = useWorkerProfile();
  const workerProfile = profile as WorkerProfile | undefined;

  const taxes = workerProfile?.profile?.['1099nec'] ?? {};
  const taxYears = Object.keys(taxes);

  const taxesList = taxYears
    .filter((ty) => { 
      return [
        'CREATED', 'FILED', 'ACCEPTED', 'REJECTED'
      ].indexOf(taxes[Number(ty)]?.status || 'QUEUED') >= 0 
    }).map((ty) => ({
      id: taxes[Number(ty)]?.documentId ?? '',
      url: taxes[Number(ty)]?.payerUrl ?? '',
      year: ty,
    }));

  return (
    <TabWrapper>
      <PageNavBar title="Taxes" />
      {isLoading ? (
        <LoadingScreen>
          <Spinner />
          <NormalText>Loading your tax documents</NormalText>
        </LoadingScreen>
      ) : (
        <TaxesList taxesList={taxesList} />
      )}
    </TabWrapper>
  );
}

/**
 * Support Page – currently just links out to zendesk.
 * Eventually, it'll have FAQs, conversations, etc.
 */
import { TabWrapper } from '@/app/wrappers/Containers';
import { useWorkerProfile } from '@/services/useWorkerProfile';
import { useRequestWorkerCallBack } from '@/services/useWorkerSupport';
import { trackAmplitudeEvent } from '@/shared/analytics';
import ExternalLinkPoster from '@/shared/components/ExternalLinkPoster';
import PageNavBar from '@/shared/components/PageNavBar';
import { Bold, SmallText } from '@/shared/components/Text.styled';
import { toast } from '@/shared/components/Toaster/Toaster';
import { SUPPORT_HOME_PAGE } from '@/shared/helpers';
import { Button, Spinner } from '@checkrx/pay-component-library';
import { useState } from 'react';
import styled from 'styled-components';
import { Drawing, Headline, SupportText, SupportView } from './SupportPage.styled';

const VerticalStack = styled.div`
  display: grid;
  gap: 5px;
  width: 100%;
`;

export default function SupportPage() {
  const [shouldSendExternalLink, setShouldSendExternalLink] = useState(false);

  const { data: workerProfile } = useWorkerProfile();
  const workerIsEnabledForPhoneSupport =
    workerProfile?.customer?.featureFlags?.workerPhoneSupport?.enabled;
  const callbackPhoneNumber = workerProfile?.profile?.contactPhone;

  const {
    mutate: requestWorkerCallBack,
    isLoading: requestingCallBack,
    isSuccess: requestedCallBack,
  } = useRequestWorkerCallBack({
    onError: () => {
      toast({
        type: 'error',
        message: 'There was an error requesting a call back from support',
        duration: 4500,
      });
    },
    onSuccess: () => {
      toast({
        type: 'success',
        message: `Success! You should receive a call back on ${callbackPhoneNumber} shortly.`,
        duration: 4500,
      });
    },
  });

  const onRequestCallBackClick = () => {
    trackAmplitudeEvent('Earner Requested Call Back');
    requestWorkerCallBack();
  };

  const isWeekend = (date: Date) => date.getDay() % 6 === 0;

  const renderRequestCallBackButton = () => {
    if (workerIsEnabledForPhoneSupport) {
      if (requestingCallBack) {
        return <Spinner />;
      } else if (requestedCallBack) {
        return (
          <VerticalStack>
            <Bold>Call back requested. An agent will call you on {callbackPhoneNumber}</Bold>
            <SmallText>
              If after hours or weekend, an agent will call the next business day between 8am - 8pm
              ET
            </SmallText>
            <SmallText>
              To maintain your spot in the queue, please do not request another callback until
              you&apos;ve been contacted by support
            </SmallText>
          </VerticalStack>
        );
      } else {
        if (!isWeekend(new Date())) {
          return (
            <VerticalStack>
              <Button
                text="Request call back from a support agent"
                width="100%"
                colorVariant="brand"
                sizeVariant="small"
                onClick={onRequestCallBackClick}
              />
              <SmallText>Available M-F, 8am - 8pm Eastern</SmallText>
            </VerticalStack>
          );
        }
      }
    }
  };

  return (
    <TabWrapper>
      <PageNavBar title="Help Center" />
      <SupportView>
        <Drawing className="help-center-drawing" />
        <Headline>We&apos;re here to help!</Headline>

        {renderRequestCallBackButton()}

        <SupportText>
          Tap below to launch our help desk. You will be directed to{' '}
          <Bold>
            <a href="https://support.checkrpay.com" target="_blank" rel="noreferrer">
              support.checkrpay.com
            </a>
          </Bold>
          . There you can use our chat features or open a support ticket.
        </SupportText>
        <SupportText>
          If you need to dispute a debit card transaction or report your card lost or stolen, call
          Visa Card Services at{' '}
          <Bold>
            <a href="tel:18887330027">1-888-733-0027</a>
          </Bold>
          . Visa can assist with getting your Card canceled or initiating a transaction dispute.
        </SupportText>
        {shouldSendExternalLink && (
          <ExternalLinkPoster
            link={SUPPORT_HOME_PAGE}
            prependText="Support"
            onSent={() => setShouldSendExternalLink(false)}
          />
        )}
        <Button
          text="Launch Help Center"
          width="100%"
          colorVariant="accent"
          sizeVariant="big"
          onClick={() => {
            setShouldSendExternalLink(true);
          }}
        />
      </SupportView>
    </TabWrapper>
  );
}

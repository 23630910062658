/**
 * Manage Card Page – currently just links out to zendesk.
 * See git for a previous version of this file with more functionality.
 */
import { Bold, NormalText, SubTitleText } from '@/shared/components/Text.styled';
import { Button, Spinner } from '@checkrx/pay-component-library';
import { Break, Drawing, SupportText, SupportView } from '../SupportPage.styled';

import ExternalLinkPoster from '@/shared/components/ExternalLinkPoster';
import PageNavBar from '@/shared/components/PageNavBar';
import { SUPPORT_CARD_MANAGEMENT_PAGE } from '@/shared/helpers';

import { LoadingScreen, TabWrapper } from '@/app/wrappers/Containers';
import { useBankingCardAndToken, usePinStatus } from '@/services/useBankingCard';
import { colors } from '@/shared/styles';
import { useState } from 'react';
import styled from 'styled-components';
import { ChangePin } from './ChangePin';
import { SetupPin } from './SetupPin';

const SupportContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const WarningText = styled(SupportText)`
  color: ${colors.accentOrange};
  font-weight: bold;
  font-size: 12px;
`;

const ManagePinPage = () => {
  const { data, isLoading: loadingCardAndToken } = useBankingCardAndToken();
  const card = data?.card;
  const { data: pinStatus, isLoading: loadingPinStatus } = usePinStatus(card?.cardId ?? '', {
    enabled: !!card?.cardId,
  });
  const [shouldSendExternalLink, setShouldSendExternalLink] = useState(false);

  if (loadingPinStatus || loadingCardAndToken) {
    return (
      <TabWrapper>
        <PageNavBar title="Manage PIN" />
        <LoadingScreen>
          <Spinner />
          <NormalText>Loading your card information...</NormalText>
        </LoadingScreen>
      </TabWrapper>
    );
  }

  const token = data?.bankToken;

  return (
    <>
      <TabWrapper>
        <PageNavBar title="Manage PIN" />
        <SupportView>
          {!!card && !!token ? (
            <SupportContainer>
              <SubTitleText>
                {pinStatus === 'NotSet' ? 'Set' : 'Change'} your PIN for card ending in{' '}
                {card?.lastFour}
              </SubTitleText>
              <SupportText>
                {pinStatus === 'NotSet'
                  ? `You can set your PIN even before it arrives in the mail 
                  so it's ready to go after it arrives.`
                  : 'You can change your PIN at any time to ensure the security of your card.'}
              </SupportText>
              <WarningText>
                Warning: You have a maximum of 3 attempts to{' '}
                {pinStatus === 'NotSet' ? 'set' : 'change'} your PIN in a 24-hour period.
              </WarningText>
              {pinStatus === 'NotSet' ? (
                <SetupPin card={card} token={token} />
              ) : (
                <ChangePin card={card} token={token} />
              )}
            </SupportContainer>
          ) : (
            <Drawing className="manage-card-drawing" />
          )}

          <SupportContainer>
            <SupportText>
              Tap below to launch our help desk. You will be directed to{' '}
              <Bold>
                <a href="https://support.checkrpay.com" target="_blank" rel="noreferrer">
                  support.checkrpay.com
                </a>
              </Bold>
              . There you can use our chat features or open a support ticket.
              <Break />
              If you need to dispute a debit card transaction or report your card lost or stolen,
              call Visa Card Services at{' '}
              <Bold>
                <a href="tel:18887330027">1-888-733-0027</a>
              </Bold>
              . Visa can assist with getting your Card canceled or initiating a transaction dispute.
            </SupportText>
            <Button
              text="Launch Help Center"
              width="100%"
              colorVariant="accent"
              sizeVariant="big"
              onClick={() => {
                setShouldSendExternalLink(true);
              }}
            />
          </SupportContainer>
        </SupportView>
      </TabWrapper>
      {shouldSendExternalLink && (
        <ExternalLinkPoster
          onSent={() => setShouldSendExternalLink(false)}
          link={SUPPORT_CARD_MANAGEMENT_PAGE}
          prependText="Card Management"
        />
      )}
    </>
  );
};

export default ManagePinPage;
